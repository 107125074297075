import React from "react";
import { toast } from "react-toastify";
import axios from "axios";

const FinalizeQuestions = async (
  answers,
  tempRadioAnswers,
  analysis,
  props,
  navigate,
  setSaving,
  files,
  questions,
  answerFromDb
) => {
  try {
    const mergedAnswers = { ...answers};
    for (const key in tempRadioAnswers) {
      mergedAnswers[key] = {
        id: tempRadioAnswers[key].id,
        questionAnswer: tempRadioAnswers[key].answer,
        index: tempRadioAnswers[key].id,
      };
    }

    const filledAnswers = Object.keys(mergedAnswers).filter(
      (key) => mergedAnswers[key].questionAnswer !== "" && mergedAnswers[key].id !== null
    ).map((key) => Number(key))
    
    let requiredQuestions = questions.filter(
      question => question.clientType === analysis.clientType && question.dependencyQuestionId === null
    );

    if (analysis.clientType === 'PSP') {
      requiredQuestions = requiredQuestions.filter(question => question.id !== 222 && question.id !== 216);
    }

    const requiredQuestionIds = requiredQuestions.map(question => question.id);

    const filteredNonNullAnswers = filledAnswers.filter(answer => requiredQuestionIds.includes(Number(answer)));

    const allIdsPresent = requiredQuestionIds.every(questionId =>
      filteredNonNullAnswers.some(answer => Number(answer) === Number(questionId))
    );

    const missingAnswers = requiredQuestionIds.filter(id => !filteredNonNullAnswers.includes(id))
    console.debug(missingAnswers, 'missingAnswers')

    // console.debug("requiredQuestionIds:", requiredQuestionIds);
    // console.debug("filteredNonNullAnswers:", filteredNonNullAnswers);
    console.debug("allIdsPresent:", allIdsPresent);

    if (!allIdsPresent) {
      return toast.error("Por favor, responda à todas as perguntas para finalizar o envio do formulário.");
    }

    setSaving(true);

    const formattedAnswers = filteredNonNullAnswers.map(key => ({
      id: mergedAnswers[key].id,
      questionAnswer: mergedAnswers[key].questionAnswer,
      index: mergedAnswers[key].index,
    }));

    await axios.post(
      `${process.env.REACT_APP_API_URL}/form/${analysis.id}`,
      formattedAnswers,
      {
        headers: {
          Authorization: props.token,
          "Content-Type": "application/json",
        },
      }
    );


    toast.success("Formulário enviado com sucesso.");
    navigate("/homeregistration/");
  } catch (error) {
    console.error("Erro ao enviar formulário:", error);
    toast.error("Erro ao enviar formulário.");
  } finally {
    setSaving(false);
  }
};

export default FinalizeQuestions;
