import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Header from "../../../components/Header2";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloudDownloadOutlined from "@mui/icons-material/CloudDownloadOutlined";
import { ButtonBase, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import ForecastPreview from "./ForecastPreview";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";

require("../../tokenRenewal");

const fileTypes = ["XLSX"];

const Footer = styled(Container)({
  display: "flex",
  justifyContent: "end",
  padding: "30px",
});

const UploadSection = styled("span")({
  fontWeight: "bold",
  color: "#e5007e",
  "& > svg": {
    verticalAlign: "sub",
  },
});

const SmallDivider = styled(Divider)({
  borderColor: "#e5007e",
  width: "40%",
  marginBottom: "8px",
});

function getMccsValue(data) {
  const mccs = [];
  for (let i = 18; i < data.length && !!data[i].__EMPTY; i += 3) {
    mccs.push({
      mcc: data[i].__EMPTY,
      mccBillingProjectionByProduct: data[i].__EMPTY_1 * 100,
      debit: data[i].__EMPTY_3,
      mccTotalTpv: data[i].__EMPTY_4,
      averageTicketDebit: data[i].__EMPTY_5,
      credit: data[i + 1].__EMPTY_3,
      averageTicketCredit: data[i + 1].__EMPTY_5,
      installment: data[i + 2].__EMPTY_3,
      averageTicketInstallments: data[i + 2].__EMPTY_5,
    });
  }

  return mccs;
}

function getInformation(data) {
  return {
    billingMonth1: get(data, "[1].__EMPTY_1", 0),
    billingMonth2: get(data, "[2].__EMPTY_1", 0),
    billingMonth3: get(data, "[3].__EMPTY_1", 0),
    billingMonth4: get(data, "[4].__EMPTY_1", 0),
    billingMonth5: get(data, "[5].__EMPTY_1", 0),
    billingMonth6: get(data, "[6].__EMPTY_1", 0),
    billingMonth7: get(data, "[7].__EMPTY_1", 0),
    billingMonth8: get(data, "[8].__EMPTY_1", 0),
    billingMonth9: get(data, "[9].__EMPTY_1", 0),
    billingMonth10: get(data, "[10].__EMPTY_1", 0),
    billingMonth11: get(data, "[11].__EMPTY_1", 0),
    billingMonth12: get(data, "[12].__EMPTY_1", 0),
    billingMonthTotal: get(data, "[13].__EMPTY_1", 0),
    performanceByDebit: Math.round(get(data, "[1].__EMPTY_4", 0) * 100).toFixed(2),
    performanceByCredit: Math.round(get(data, "[2].__EMPTY_4", 0) * 100).toFixed(2),
    performanceByInstallment: Math.round(get(data, "[3].__EMPTY_4", 0) * 100).toFixed(2),
    performanceWithCard: Math.round(get(data, "[6].__EMPTY_4", 0) * 100).toFixed(2),
    performanceWithoutCard: Math.round(get(data, "[7].__EMPTY_4", 0) * 100).toFixed(2),
    prepayment: Math.round(get(data, "[9].__EMPTY_4", 0) * 100).toFixed(2),
    averageSettlementTerm: get(data, "[10].__EMPTY_4", 0),
  };
}


function Forecast(props) {
  const [data, setData] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [mccs, setMccs] = useState([]);
  const analysis = JSON.parse(localStorage.getItem("analysis"));
  const navigate = useNavigate();

  const readExcel = (file) => {
    setIsUploading(true);
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[1];
        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, { range: 1 });
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setPreviewOpen(true);
      setIsUploading(false);
      setData(d);
      setMccs(getMccsValue(d));
    });
  };
  const handleCancel = () => setPreviewOpen(false);

  const handleSave = async () => {
    setIsSaving(true);
    const foreacastOperation = getInformation(data);
  
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/forecast/${analysis.id}`,
        mccs,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );
      await axios.post(
        `${process.env.REACT_APP_API_URL}/forecastgeneraloperation/${analysis.id}`,
        foreacastOperation,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );
  
      //Envia email para risco
      /*const response = await axios.get(
        process.env.REACT_APP_API_NO_TOKEN_URL + "/email/risk/" + analysis.id,
      );*/
  
      toast.success("Forecast preenchido com sucesso!");
      navigate("/homeregistration/");
    } catch (error) {
      console.error(error);
      if (error.response) {
        if (error.response.status === 400) {
          alert("Erro ao realizar o envio! Por favor, verifique os dados e se está utilizando o template disponibilizado para o preenchimento do forecast e tente novamente.");
        } else if (error.response.status === 500) {
          alert("Houve um problema no servidor. Por favor, tente novamente mais tarde.");
        } else {
          alert("Erro ao enviar o Forecast. Por favor, tente novamente.");
        }
      } else {
        alert("Erro ao enviar o Forecast. Por favor, tente novamente.");
      }
    } finally {
      setIsSaving(false);
    }
  };
  
  
  
  return (
    <>
      <Header name={"Forecast"} token={props.token} />
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Container>
          <h1 className="center">Forecast</h1>

          {!previewOpen && (
            <Grid className="center" container spacing={4}>
              <h3>
                Precisamos saber os segmentos de atuação dos seus clientes e as
                informações financeiras.{" "}
              </h3>
              <Grid item xs={6}>
                {/* <Card sx={{ minWidth: 10 }}> */}
                {/* <CardContent > */}
                {/* <p> */}

                {/* </UploadSection> */}
                {/* </p> */}
                {/* </CardContent> */}
                {/* </Card> */}

                <p>
                  Gostaríamos de pedir sua colaboração no preenchimento do
                  forecast, pois ele será utilizado para definir a composição da garantia com a EntrePay.
                </p>

                <p>
                  <strong>1.</strong> Faça o download do Template do Forecast
                  que deve ser preenchido:{" "}
                </p>
                {/* <UploadSection> */}
                <Button href="/templateForecast.xlsx">
                  <div className="cardForecastButton">
                    <CloudDownloadOutlined />
                    Download do template do forecast
                  </div>
                </Button>
                <p>
                  <strong>2.</strong> Preencha este documento com os dados
                  solicitados e, em seguida, faça o upload dele ao lado:{" "}
                </p>
                <p style={{paddingTop:'30px'}}>
                  Agradecemos muito pela sua participação e comprometimento
                  nesse processo!
                </p>
              </Grid>
              <Grid item xs={6}>
                <Card
                  sx={{
                    minWidth: 30,
                    borderStyle: "dotted",
                    borderColor: "#e5007e",
                  }}
                >
                  <CardContent>
                    {" "}
                    <FileUploader
                      handleChange={readExcel}
                      name="file"
                      types={fileTypes}
                      children={
                        <div style={{ textAlign: "center", color: "black" }}>
                          {isUploading && <CircularProgress />}
                          {!isUploading && (
                            <>
                              <p>
                                <UploadSection>
                                  <CloudUploadOutlinedIcon
                                    sx={{ width: 200, height: 80 }}
                                  />
                                </UploadSection>
                              </p>
                              <p>Arraste e solte o arquivo</p>
                              <p>preenchido aqui</p>
                              <br />
                              <div style={{ display: "flex" }}>
                                <SmallDivider />
                                <div
                                  style={{
                                    marginLeft: "7%",
                                    marginRight: "7%",
                                  }}
                                >
                                  OU
                                </div>
                                <SmallDivider />
                              </div>
                              <br />
                              <p>Clique para buscar</p>
                            </>
                          )}
                        </div>
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
          {previewOpen && <ForecastPreview data={data} mccs={mccs} />}
        </Container>

        <br />
      </Box>

      <Footer sx={{ maxWidth: "90rem" }}>
        {previewOpen && (
          <Button
            sx={{
              color: "#e5007e",
              backgroundColor: "white",
              borderRadius: "2.5rem",
              border: "solid 1px",
              borderColor: "#e5007e",
              fontSize: "14px",
              shadow: "none",
              fontWeight: "500",
              mr: 2,
              "&:hover": {
                color: "#e5007e",
                backgroundColor: "white",
              },
            }}
            variant="contained"
            onClick={handleCancel}
          >
            Cancelar
          </Button>
        )}
        <Button
          sx={{
            marginRight: "20px",
            backgroundColor: "#e5007e",
            borderRadius: "2.5rem",
            border: "solid 1px",
            borderColor: "#e5007e",
            fontSize: "14px",
            paddingInline: "15px",
            shadow: "none",
            fontWeight: "500",
            ml: 5,
            "&:hover": {
              backgroundColor: "#e5007e",
              color: "white",
              // border: "solid 1px",
            },
          }}
          variant="contained"
          onClick={handleSave}
          disabled={isSaving}
        >
          Continuar {isSaving && <CircularProgress />}
        </Button>
      </Footer>
    </>
  );
}

export default Forecast;
