import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { formatTelefone } from "../utils/formattedTexts";

function TableContacts({ questions, handleTableChange, answers }) {
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    // Initialize inputValues with answers
    const initialInputValues = {};
    questions.forEach((question) => {
      initialInputValues[question.id] = answers[question.id]?.questionAnswer || "";
    });
    setInputValues(initialInputValues);
  }, [questions, answers]);

  const handleInputChange = (questionId, value) => {
    const updatedValues = { ...inputValues, [questionId]: value };
    setInputValues(updatedValues);
    handleTableChange(questionId, value);
  };

  const isMobile = window.innerWidth < 600;

  const renderQuestions = (groupQuestions) => {
    return groupQuestions.map((question) => {
      let answer = inputValues[question.id] || "";

      if (question.question === "4.4 Telefone") {
        answer = formatTelefone(answer);
      }

      return (
        <tr key={question.id}>
          <td style={{ paddingRight: "20px" }}>
            <label>{question.question}</label>
          </td>
          <td>
            <TextField
              sx={{ backgroundColor: "#f7f7f7" }}
              type="text"
              value={answer}
              id={question.id}
              onChange={(event) => handleInputChange(question.id, event.target.value)}
            />
          </td>
        </tr>
      );
    });
  };

  const group1Questions = questions.filter(
    (question) => question.questionIdentifier === "4.1"
  );
  const group2Questions = questions.filter(
    (question) => question.questionIdentifier === "4.2"
  );

  return (
    <div style={{ flexDirection: isMobile ? "column" : "row", display: "flex" }}>
      <div
        style={{
          marginRight: isMobile ? "0" : "10%",
          width: isMobile ? "100%" : "50%",
        }}
      >
        <h4>Contato 1</h4>
        <table>
          <tbody>{renderQuestions(group1Questions)}</tbody>
        </table>
      </div>
      <div
        style={{
          marginRight: isMobile ? "0" : "10%",
          width: isMobile ? "100%" : "50%",
        }}
      >
        <h4>Contato 2</h4>
        <table>
          <tbody>{renderQuestions(group2Questions)}</tbody>
        </table>
      </div>
    </div>
  );
}

export default TableContacts;
