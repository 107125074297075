import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import HeaderHome from "../../../components/Header2";
import Stepper from "../../../components/Stepper";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import IconForecast from "../../../assets/forecast.png";
import IconUpload from "../../../assets/uploads.png";
import IconForm from "../../../assets/form.png";
import CenteredCircularProgress from "../../../components/CenteredCircularProgress";
import Button from "@mui/material/Button";
import SentUploads from "../Uploads/SentUploads";
import Footer from "../../../components/Footer";
import StatusModal from "../../../components/StatusModal";

require("../../tokenRenewal");

const CardStyled = styled(Card)({
  margin: 15,
  boxShadow: "0 0 1em #9A9A9A",
  // padding: 5,
  height: 110,
  width: 110,
  transition: "transform .2s" /* Animation */,

  "&:hover": {
    transform: "scale(1.2)",
  },
});

function HomeRegistration(props) {
  const handleDownload = async () => {
    try {
      const response = await axios.get("/Contrato_Geral_2023.pdf", {
        responseType: "blob",
      }); // Substitua o caminho pelo caminho correto do seu arquivo PDF
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      const analysis = JSON.parse(localStorage.getItem("analysis"));

      link.href = url;
      link.download = "Contrato_Geral_2023.pdf"; // Substitua "arquivo.pdf" pelo nome que desejar para o arquivo no download
      link.click();
      URL.revokeObjectURL(url); // Libera o URL Blob após o download
    } catch (error) {
      console.error(error);
    }
  };

  const [form, setForm] = useState([]);
  const [analysis, setAnalysis] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getStatus = React.useCallback(async () => {
    setLoading(true);
    try {
      const email = localStorage.getItem("email");
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/analysisbyemail/" + email,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );
      localStorage.setItem("analysis", JSON.stringify(response.data));
      //console.log(response.data, "analysiss");
      const id = response.data.id;
      const responseForm = await axios.get(
        process.env.REACT_APP_API_URL + "/form/" + id,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );
      setForm(responseForm.data);

      setAnalysis(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [props.token, navigate]);

  React.useEffect(() => {
    getStatus();
    // console.log(analysis, "aaa");
  }, [getStatus]);

  React.useEffect(() => {
    if (analysis) {
      const analysisStatusForm = analysis.statusForm;
      props.setStatusForm(analysisStatusForm);
      const analysisStatusUpload = analysis.statusUpload;
      props.setStatusUpload(analysisStatusUpload);
    }
  }, [getStatus, analysis]);

  const statusRisk = analysis.statusRisk;
  const statusCompliance = analysis.statusCompliance;

  let message;
  const renderStatus = (status, message) => (
    <>
      <h3>
        <strong>Status do processo:</strong>
      </h3>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={status}></div>
        <div className="status-container">
          <h4 style={{ margin: 0 }}>{message}</h4>
        </div>
      </div>
    </>
  );

  const renderAnalysisMessage = () => (
    <>
      {renderStatus("status", "Em análise.")}
      <p>
        Por favor, preencha todos os dados requisitados no portal para que o
        nosso time de risco faça a análise.
      </p>
      <p>Em caso de dúvidas, entre em contato com seu Gerente Comercial.</p>
    </>
  );

  const renderApprovedMessage = () => (
    <>
      {renderStatus("statusAprovado", "Proposta aprovada!")}
      <br />
     
    </>
  );

  const equalsIgnoreCase = (str1, str2) => {
    if (typeof str1 === "string" && typeof str2 === "string") {
      return str1.toLowerCase() === str2.toLowerCase();
    }
    return false;
  };

  if (analysis.clientType === "PSP") {
    if (equalsIgnoreCase(statusRisk, "EM ANALISE")) {
      message = renderAnalysisMessage();
    } else if (
      equalsIgnoreCase(statusRisk, "APROVADO") &&
      equalsIgnoreCase(statusCompliance, "APROVADO")
    ) {
      message = renderApprovedMessage();
    }
  } else {
    if (equalsIgnoreCase(statusRisk, "EM ANALISE")) {
      message = renderAnalysisMessage();
    } else if (equalsIgnoreCase(statusRisk, "APROVADO")) {
      message = renderApprovedMessage();
    }
  }

  return (
    <div>
      <HeaderHome token={props.token} />
      <div className="title">
        <h2>Portal de Clientes - Entrepay</h2>
        <p>
          Bem vindo ao Portal de Clientes! Por favor, faça o processo de
          cadastro abaixo para continuarmos:{" "}
        </p>
      </div>
      {loading && <CenteredCircularProgress />}
      {!loading && (
        <>
          {/* <Stepper activeStep={0} /> */}
          <Grid
            className="menuHome"
            spacing={8}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ textAlign: "center" }}
          >
            <Grid className="statusHome" item xs={2}>
              {message}
            </Grid>
            <Grid className="cardHome" item xs={4} direction="row">
              <Card
                sx={{
                  marginTop: 3,
                  marginBottom: 10,
                  boxShadow: "0 0 1em #DCDCDC",
                  padding: 10,
                }}
              >
                <Typography sx={{ marginBottom: 3, fontWeight: "bold" }}>
                  Cadastro
                </Typography>
                <Typography sx={{ marginLeft: 2, marginBottom: 3 }}>
                  Comece aqui ou continue de onde parou:
                </Typography>
                <Grid container justifyContent="center">
                  {" "}
                  {/* Centraliza os Cards */}
                  <Grid item>
                    <CardStyled sx={{ paddingTop: 4 }}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          {analysis.statusForm === "COMPLETO" ? (
                            <img
                              src={IconForm}
                              alt=""
                              style={{
                                width: 70,
                                height: 60,
                                marginBottom: 3,
                                cursor: "not-allowed",
                                opacity: 0.5,
                              }}
                            />
                          ) : (
                            <a href="/form">
                              <img
                                src={IconForm}
                                alt=""
                                style={{
                                  width: 70,
                                  height: 60,
                                  marginBottom: 3,
                                }}
                              />
                            </a>
                          )}
                          <br />
                        </Grid>
                        <Grid item>
                          {analysis.statusForm === "COMPLETO" ? (
                            <>
                              <Link
                                style={{
                                  color: "grey",
                                  cursor: "not-allowed",
                                  opacity: 0.5,
                                }}
                                className="linkMenu"
                                to={"/homeRegistration"}
                              >
                                Formulário
                              </Link>
                            </>
                          ) : (
                            <Link className="linkMenu" to={"/form/"}>
                              Formulário
                            </Link>
                          )}
                        </Grid>
                      </Grid>
                    </CardStyled>
                  </Grid>
                  
                  <Grid item>
                    <CardStyled sx={{ paddingTop: 4 }}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          {analysis.statusUpload === "COMPLETO" ? (
                            <img
                              src={IconUpload}
                              alt=""
                              style={{
                                width: 70,
                                height: 60,
                                marginBottom: 3,
                                cursor: "not-allowed",
                                opacity: 0.5,
                              }}
                            />
                          ) : (
                            <a href="/uploads">
                              <img
                                src={IconUpload}
                                alt=""
                                style={{
                                  width: 70,
                                  height: 60,
                                  marginBottom: 3,
                                }}
                              />
                            </a>
                          )}
                          <br />
                        </Grid>
                        <Grid item>
                          {analysis.statusUpload === "COMPLETO" ? (
                            <>
                              <Link
                                style={{
                                  color: "grey",
                                  cursor: "not-allowed",
                                  opacity: 0.5,
                                }}
                                className="linkMenu"
                                to={"/homeRegistration"}
                              >
                                Uploads
                              </Link>
                            </>
                          ) : (
                            <Link className="linkMenu" to={"/uploads/"}>
                              Uploads
                            </Link>
                          )}
                        </Grid>
                      </Grid>
                    </CardStyled>
                  </Grid>
                  <Grid item>
                    <CardStyled sx={{ paddingTop: 4 }}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <a href="/forecast">
                            <img
                              src={IconForecast}
                              alt=""
                              style={{ width: 70, height: 60, marginBottom: 3 }}
                            />
                          </a>
                        </Grid>
                        <Grid item>
                          <Link
                            className="linkMenu"
                            to={"/forecast/"}
                            sx={{ textAlign: "center" }}
                          >
                            Forecast
                          </Link>
                        </Grid>
                      </Grid>
                    </CardStyled>
                  </Grid>
                </Grid>
                {/* <SentUploads /> */}
              </Card>
            </Grid>

            {/* <Grid className="statusHome"item xs={2}>
              {message}
            </Grid> */}
          </Grid>
          <Footer />
        </>
      )}
    </div>
  );
}
export default HomeRegistration;
