import * as React from "react";
import { Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import logo from "../../src/assets/Logo-Entrepay-Fundo-Vazio.png";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../index";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { TfiMenu } from "react-icons/tfi";
import { FaHome, FaWpforms } from "react-icons/fa";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FiUpload } from "react-icons/fi";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import StatusModal from "./StatusModal";
import { PiSignOut } from "react-icons/pi";
import SaveQuestions from "../pages/Form/SaveQuestions";

require("../pages/tokenRenewal");

const HeaderLogo = styled("img")({
  width: "254px",
  height: "60px",
});

const logoStyled = {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
};

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  zIndex: 99,
};

const modalOverlayStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 80,
};

function Header({token, onClick}) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const location = useLocation();
  const [analysis, setAnalysis] = useState({});
  const statusValue = analysis.statusRisk;
  const [showModalStatus, setShowModalStatus] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // const onClickNext = () => {
  //   SaveQuestions(
  //     answers,
  //     tempRadioAnswers,
  //     analysis,
  //     props,
  //     navigate,
  //     setSaving
  //   );
  // };


  const getStatus = React.useCallback(async () => {
    try {
      const email = localStorage.getItem("email");
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/analysisbyemail/" + email,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      localStorage.setItem("analysis", JSON.stringify(response.data));

      const id = response.data.id;

      setAnalysis(response.data);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, [token, navigate]);

  const handleClickLink = (path) => {
    setCurrentPage(path);
  };

  const handleNavigation = (path) => {
    if (!showModal && location.pathname === "/form") {
      handleClickLink(path);
      setShowModal(true);
    } else {
      navigate(path);
    }
  };

  const handleConfirmExitWithoutSaving = () => {
    setShowModal(false);
    if (currentPage) {
      navigate(currentPage);
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const navigateRoute = (url) => {
    navigate(`/${url}`);
  };

  const handleStatusProcessClick = () => {
    setShowModalStatus(true);
  };

  const handleCloseModal = () => {
    setShowModalStatus(false);
  };

  React.useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleClick(event) {
    event.preventDefault();
  }

  const onClickSignOut = async (event) => {
    try {
      localStorage.clear();
      await signOut(auth);
      navigate("/signin/");
    } catch (error) {}
  };

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/signin/");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  React.useEffect(() => {
    getStatus();
  }, [getStatus]);

  const MenuOptions = [
    {
      text: "Home",
      icon: <FaHome className="menuIcon" />,
      onClick: () => handleNavigation("/homeregistration"),
    },
    {
      text: "Formulário",
      icon: <FaWpforms className="menuIcon" />,
      onClick: () => navigateRoute("form"),
    },
    {
      text: "Forecast",
      icon: <FaMoneyBillTrendUp className="menuIcon" />,
      onClick: () => handleNavigation("/forecast"),
    },

    {
      text: "Uploads",
      icon: <FiUpload className="menuIcon" />,
      onClick: () => handleNavigation("/uploads"),
    },
    
     
    
  ]

  if (isMobile) {
    MenuOptions.push(
      {
        text: "Status do Processo",
        // icon: <YourStatusIconComponent />,
        onClick: handleStatusProcessClick,
      },
      {
        text: "Sair",
        icon: <PiSignOut />,
        onClick: onClickSignOut,
      }
    );
  }


  return (
    <>
      <div className="header-wrap"></div>
      <div className="header2">
        <div className="logo-and-drawer">
          <div title="Menu" className="headerMenu">
            <TfiMenu className="headerButtonMenu" onClick={toggleDrawer} />

            <Drawer open={open} onClose={toggleDrawer}>
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer}
              >
                <Divider />

                <List>
                  {MenuOptions.map((item, index) => (
                    <ListItem key={item.text} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          item.onClick();
                        }}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>

                        <ListItemText primary={item.text} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Drawer>
          </div>
          <div style={logoStyled}>
            <a title="Home" href="/homeRegistration">
              <HeaderLogo className="logo" src={logo} />
            </a>
          </div>
        </div>

        <div className="headerMenu">
          {!isMobile && (
            <div
              sx={{
                textAlign: "center",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
              role="presentation"
              onClick={handleClick}
            >
              {/* <Button
                onClick={handleStatusProcessClick}
                sx={{
                  color: "#e5007e",
                  backgroundColor: "white",
                  borderRadius: "2.5rem",
                  border: "solid 1px",
                  borderColor: "#e5007e",
                  fontSize: "14px",
                  shadow: "none",
                  fontWeight: "500",
                }}
                title="Clique para verificar o Status do seu processo."
              >
                Status do Processo
              </Button> */}
            </div>
          )}
          {!isMobile && (
            <div sx={{ textAlign: "end", mr: 10, mb: 3, width: "10%" }}>
              <Button
                className="sair"
                onClick={onClickSignOut}
                sx={{
                  marginRight: "20px",
                  backgroundColor: "#e5007e",
                  borderRadius: "2.5rem",
                  border: "solid 1px",
                  borderColor: "#e5007e",
                  fontSize: "14px",
                  paddingInline: "15px",
                  shadow: "none",
                  fontWeight: "500",
                  ml: 5,
                  "&:hover": {
                    backgroundColor: "#e5007e",
                    color: "white",
                  },
                }}
                variant="contained"
                size="small"
              >
                Sair
              </Button>
            </div>
          )}
        </div>
      </div>
      <Divider sx={{ marginBottom: "30px" }} />
      {showModal && (
        <Box sx={modalStyles}>
          <div>
            <p>
              Algumas alterações no formulário não foram salvas. Deseja salvar?{" "}
            </p>
            <div className="modal-content">
              <Button
                sx={{ color: "#e5007e", padding: 0 }}
                onClick={() => setShowModal(false)}
              >
                Cancelar
              </Button>
              <Button
                sx={{ color: "#e5007e", fontWeight: "bold" }}
                onClick={() =>
                  onClick()
                }
              >
                Salvar e sair
              </Button>
            </div>
          </div>
        </Box>
      )}
      {showModalStatus && (
        <StatusModal
          showModalStatus={showModalStatus}
          setShowModalStatus={setShowModalStatus}
          handleConfirmExitWithoutSaving={handleConfirmExitWithoutSaving}
          token={token}
        />
      )}

      {showModalStatus && (
        <div style={modalOverlayStyles} onClick={handleCloseModal} />
      )}
    </>
  );
}

export default Header;
