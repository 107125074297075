export const documentosVAR = [
  { name: "Cartão CNPJ da empresa", id: "cartaoCnpj" },
  { name: "Contrato Social atualizado da empresa (Obrigatório*)", id: "contract" },
  { name: "Comprovante de endereço da empresa (Obrigatório*)", id: "varProof" },
  { name: "Balanço Financeiro e DRE dos 2 anos anteriores (Obrigatório*)", id: "financialBalance" },
  { name: "Caso deseje enviar outros arquivos que não estejam listados, envie aqui:", id: "others" },
  
  ];
  
  export const sociosVAR = {
  cpf11: [
  { name: "Documento de identidade com foto (Obrigatório*)", id: "RG" },
  { name: "Documento CPF (Obrigatório*)", id: "CPF" },
  { name: "Comprovante de Residência do sócio (Obrigatório*)", id: "proofResidencePartner" },
  { name: "Declaração do Imposto de renda dos 2 anos anteriores (Obrigatório*)", id: "tax" }
  ],
  cpfMais11: [
    { name: "Documento de identidade com foto (Obrigatório*)", id: "RG" },
    { name: "Documento CPF (Obrigatório*)", id: "CPF" },
    { name: "Comprovante de Residência do sócio (Obrigatório*)", id: "proofResidencePartner" },
    { name: "Declaração do Imposto de renda dos 2 anos anteriores (Obrigatório*)", id: "tax" }
  ]
  };
  
  export const documentosPSP = [
  { name: "Cartão CNPJ da empresa (Obrigatório*)", id: "cartaoCnpj" },
  { name: "Contrato Social atualizado da empresa (Obrigatório*)", id: "contract" },
  { name: "Balanço Financeiro e DRE dos 2 anos anteriores (Obrigatório*)", id: "financialBalance" },
  { name: "Comprovante de endereço da empresa (Obrigatório*)", id: "proofResidence" }
  ];
  
  export const sociosPSP = {
  cpf11: [
  { name: "Documento de identidade com foto (Obrigatório*)", id: "RG" },
  { name: "Documento CPF (Obrigatório*)", id: "CPF" },
  { name: "Declaração do Imposto de renda dos 2 anos anteriores (Obrigatório*)", id: "tax" },
  { name: "Comprovante de Residência (Obrigatório*)", id: "proofResidencePartner" },
  ],
  cpfMais11: [
    { name: "Documento de identidade com foto (Obrigatório*)", id: "RG" },
    { name: "Documento CPF (Obrigatório*)", id: "CPF" },
    { name: "Declaração do Imposto de renda dos 2 anos anteriores (Obrigatório*)", id: "tax" },
    { name: "Comprovante de Residência (Obrigatório*)", id: "proofResidencePartner" },
  ]
  };
  
  export const compliancePSP = [
  { name: "Evidência da 1ª página do contrato entre a Subadquirente e a Adquirente (pág. onde constam as informações de cadastro)", id: "evidencia_sub_adq" },
  { name: "Cópia do contrato com a registradora  (Obrigatório*)", id: "contrato_registradora" },
  { name: "Política PLD/FT (Obrigatório*)", id: "pld_ft" },
  { name: "Currículo do Profissional de Compliance/PLD (Obrigatório*)", id: "curriculo_compliance" },
  { name: "Certificado do treinamento de PLD (Obrigatório*)", id: "treinamento_pld_compliance" },
  { name: "Organograma funcional (Obrigatório*)", id: "organograma" },
  { name: "Treinamento de PLD/FT (Obrigatório*)", id: "treinamento_pld_ft" },
  { name: "Contrato de prestação de serviços", id: "contrato_prestacao" }, 
  { name: "Avaliação KYP/KYS", id: "kyp_kys" },
  { name: "Plataformas/sistemas utilizados para análise de KYC (Obrigatório*)", id: "contrato_plataformas" },
  { name: "Políticas de KYC, KYP, KYE, KYS (Obrigatório*)", id: "politicas_kyc" },
  { name: "Sistema Antifraude utilizado para monitoramento das transações (Obrigatório*)", id: "sistema_antifraude" },
  { name: "Manual de PLD/FTP e prevenção à fraude", id: "prevencao_fraude" },
  { name: "Evidência da equipe e dos procedimentos destinados a orientar os Estabelecimentos na tratativa de disputas (chargeback) (Se houver)", id: "evidencia_chargeback" },
  { name: "Evidência de acompanhamento dos indicadores", id: "indicadores" },
  { name: "Certificação PCI-DSS (Obrigatório*)", id: "certificacao_pci" },
  { name: "Caso deseje enviar outros arquivos que não estejam listados, envie aqui:", id: "others" },
  
  
  ]

  export const requiredDocumentsVAR = [
     "RG", "CPF", "tax", "proofResidencePartner", "varProof", "contract", "financialBalance"
  ];
  
  export const requiredDocumentsPSP = [
    "RG", "CPF", "tax", "proofResidencePartner", "proofResidence", "cartaoCnpj", "contract", 
    "financialBalance", "certificacao_pci", "politicas_kyc", "pld_ft", "treinamento_pld_compliance", 
    "curriculo_compliance", "organograma", "contrato_registradora", "sistema_antifraude", "contrato_plataformas"
  ];