import * as React from "react";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import { questionsLegalForm } from "../../utils/legalFormArray";
import "../Form/style.css";
import { formatCPFOrCNPJ } from "../../utils/formattedTexts";
import CenteredCircularProgress from "../../components/CenteredCircularProgress";
require("../tokenRenewal");

const TypographyStyled = styled(Typography)({
  marginTop: "20px",
  textAlign: "left",
  marginBottom: "5px",
});

const LegalForm = forwardRef((props, ref, button) => {
  const { register, handleSubmit, setValue, getValues, formState } = useForm();
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState(null); 
  const [answers, setAnswers] = useState({});
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    submitForm() {
      const formValues = getValues();
      if (button === "finalize") {
        for (const value in formValues) {
          if (
            formValues[value] === null &&
            value != "statusLegalForm" &&
            value != "dateLegalForm" &&
            value != "cpfOrCnpjLegal2" &&
            value != "legal2" &&
            value != "occupationLegal2" &&
            value != "emailLegal2"
          ) {
            return false;
          }
        }
      }

      handleSubmit(onSubmit)();

      return true;
    },
  }));

  const getAnalysis = React.useCallback(async () => {
    setLoading(true);
    try {
      const email = localStorage.getItem("email");
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/analysisbyemail/" + email,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );
      setAnalysis(response.data);
    } catch (error) {
      console.error('Error fetching analysis:', error); 
    } finally {
      setLoading(false);
    }
  }, [props.token, navigate]);

  const getAnswers = React.useCallback(async () => {
    if (!analysis) {
      console.warn('Analysis is null, skipping getAnswers');
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/legal/" + analysis.id,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );
      // console.debug('Answers data:', response.data); 
      setAnswers(response.data.legalForm || {});
    } catch (error) {
      console.error('Error fetching answers:', error); 
    } finally {
      setLoading(false);
    }
  }, [analysis, props.token]);

  useEffect(() => {
    getAnalysis();
  }, [getAnalysis]);

  useEffect(() => {
    if (analysis) {
      getAnswers();
    }
  }, [analysis, getAnswers]);

  useEffect(() => {
    if (Object.keys(answers).length > 0) {
      for (const [key, value] of Object.entries(answers)) {
        setValue(key, value);
      }
    }
  }, [answers, setValue]);

  const onSubmit = async (formData) => {
    // console.debug(formData); 

    try {
      setLoading(true);
      await axios.post(
        process.env.REACT_APP_API_URL + "/legalform/" + analysis.id,
        {
          branch: formData.branch,
          bank: formData.bank,
          account: formData.account,
          accountHolder: formData.accountHolder,
          accountHolderLegalOrNaturalPerson: formData.accountHolderLegalOrNaturalPerson,
          cpfOrCnpjAccountHolder: formData.cpfOrCnpjAccountHolder,
          legal1: formData.legal1,
          emailLegal1: formData.emailLegal1,
          cpfOrCnpjLegal1: formData.cpfOrCnpjLegal1,
          occupationLegal1: formData.occupationLegal1,
          legal2: formData.legal2,
          emailLegal2: formData.emailLegal2,
          cpfOrCnpjLegal2: formData.cpfOrCnpjLegal2,
          occupationLegal2: formData.occupationLegal2,
          pix: formData.pix,
        },
        {
          headers: {
            Authorization: props.token,
            'Content-Type': 'application/json',
          },
        }
      );
      // toast.success("Formulario preenchido com sucesso.");
      // navigate("/homeregistration/");
    } catch (error) {
      console.error('Error submitting form:', error);
      // toast.error("Erro ao preencher formulario");
    } finally {
      setLoading(false);
    }
  };

  const handleCPFOrCNPJChange = (event) => {
    const { id, value } = event.target;
    setValue(id, formatCPFOrCNPJ(value));
  };

  if (loading) {
    return (
      <Container>
        <CenteredCircularProgress />
      </Container>
    );
  }

  if (!analysis) {
    return (
      <Container>
        <CenteredCircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <h3 style={{ color: "#e5007e" }}>INFORMAÇÕES CONTRATUAIS</h3>
        {questionsLegalForm.map((question, index) => (
          <React.Fragment key={index}>
            <Typography sx={{ fontWeight: "bold", marginBottom: "10px" }}>
              {question.title}
            </Typography>
            <Grid style={{ marginBottom: "5px" }} container spacing={2}>
              {question.fields.map((field, idx) => (
                <Grid item xs={12} sm={6} key={idx}>
                  <TypographyStyled>{field.label}</TypographyStyled>
                  <FormControl fullWidth size="small">
                    <TextField
                      size="small"
                      id={field.id}
                      variant="outlined"
                      {...register(field.id)}
                      type={field.type}
                      required
                      onChange={
                        field.label.includes("CPF/CNPJ")
                          ? handleCPFOrCNPJChange
                          : undefined
                      }
                    />
                  </FormControl>
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))}
      </Box>
    </Container>
  );
});

export default LegalForm;
