import * as React from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import { styled } from "@mui/material/styles";
import Header from "../../../components/Header2";
import { Typography, Container, Grid, Card, CardContent } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SentUploads from "./SentUploads";
import {
  documentosPSP,
  documentosVAR,
  sociosVAR,
  sociosPSP,
  compliancePSP,
  requiredDocumentsVAR,
  requiredDocumentsPSP,
} from "../../../utils/UploadsArray";
import { usePartners } from "../../../context/partnerContext";
import { ButtonStyle, SendButtonStyle } from "../../Form/FormPspVar";
import FooterButtons from "../../../components/FooterButtons";
import CenteredCircularProgress from "../../../components/CenteredCircularProgress";
require("../../tokenRenewal");

const CardStyled = styled(Card)({
  width: "70%",
  padding: 0,
  borderStyle: "dotted",
  borderColor: "#e5007e",
  marginTop: 5,
});

function Uploads({ token }) {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState({});
  const analysis = JSON.parse(localStorage.getItem("analysis"));
  const navigate = useNavigate();
  const childRef = useRef(null);
  const { partners } = usePartners();
  const [uploads, setUploads] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [button, setButton] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadChange = (name, file) => {
    const temp = { ...files };
    temp[name] = file;
    setFiles(temp);
  };

  const getUploads = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/documentsupload/" + analysis.id,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      const uploadsMap = {};
      response.data.forEach((upload) => {
        uploadsMap[upload.documentType] = {
          uploadName: upload.documentType,
          fileName: upload.fileName,
        };
      });
      const db = Object.values(uploadsMap);
      setUploads(db);
    } catch (error) {
      console.error(error, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUploads();
  }, []);

  const allRequiredDocumentsUploaded = (
    requiredDocuments,
    currentUploads,
    files
  ) => {
    let uploadedDocuments = [
      ...currentUploads.map((upload) => upload.uploadName),
    ];

    Object.keys(files).forEach((key) => {
      const fileList = files[key];
      for (let i = 0; i < fileList.length; i++) {
        uploadedDocuments.push(`${key}`);
      }
    });

    uploadedDocuments = Array.from(new Set(uploadedDocuments));

    return requiredDocuments.every((requiredDoc) =>
      uploadedDocuments.some((uploadedDoc) => uploadedDoc.includes(requiredDoc))
    );
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const uploadFilesWithDelay = async (
    files,
    delay = 1000,
    isFinalize = false
  ) => {
    const filesKeySet = Object.keys(files);

    let hasFiles = false;

    for (const index in filesKeySet) {
      const key = filesKeySet[index];
      const uploadFiles = files[key];
      const data = new FormData();
      data.append(
        "complete",
        isFinalize ? index == filesKeySet.length - 1 : false
      );

      if (uploadFiles && uploadFiles.length > 0) {
        hasFiles = true;
        for (let i = 0; i < uploadFiles.length; i++) {
          data.append("files", uploadFiles[i]);
        }
      }

      setLoading(true);
      setMessage(true);
      try {
        await axios.post(
          process.env.REACT_APP_API_URL +
            "/documentupload/" +
            analysis.id +
            "/" +
            key,
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );
      } catch (error) {
        if (error.response && error.response.status === 429) {
          await sleep(delay);
          try {
            await axios.post(
              process.env.REACT_APP_API_URL +
                "/documentupload/" +
                analysis.id +
                "/" +
                key,
              data,
              {
                headers: {
                  Authorization: token,
                },
              }
            );
          } catch (retryError) {
            throw retryError;
          } finally {
            setLoading(false);
          }
        } else {
          throw error;
        }
      }

      await sleep(delay);
    }

    if (!hasFiles) {
      const data = new FormData();
      data.append("complete", isFinalize);

      setLoading(true);
      setMessage(true);
      try {
        await axios.post(
          process.env.REACT_APP_API_URL +
            "/documentupload/" +
            analysis.id +
            "/" +
            "complete",
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );
      } catch (error) {
        if (error.response && error.response.status === 429) {
          await sleep(delay);
          try {
            await axios.post(
              process.env.REACT_APP_API_URL +
                "/documentupload/" +
                analysis.id +
                "/" +
                "complete",
              data,
              {
                headers: {
                  Authorization: token,
                },
              }
            );
          } catch (retryError) {
            throw retryError;
          } finally {
            setLoading(false);
          }
        } else {
          throw error;
        }
      }
      await sleep(delay);
    }
  };

  const onClickNext = async () => {
    setLoading(true);
    setButton("save");
    try {
      await uploadFilesWithDelay(files, 1000, false);

      toast.success("Arquivos salvos com sucesso.");
      navigate("/homeregistration");
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 413) {
        toast.error("Tamanho do arquivo excede o limite");
      } else {
        toast.error("Erro ao salvar arquivos, por favor tente novamente");
      }
    } finally {
      setLoading(false);
    }
  };

  const onClickFinalize = async () => {
    setButton("finalize");
    setLoading(true);
    try {
      await getUploads();

      let hasPartners = partners.length > 0;

      const requiredDocuments =
        analysis.clientType === "VAR"
          ? requiredDocumentsVAR
          : requiredDocumentsPSP;

      const idsToFilter = ["Partner", "RG", "CPF", "tax"];

      let filteredDocuments = requiredDocuments;

      if (!hasPartners) {
        filteredDocuments = requiredDocuments.filter(
          (id) => !idsToFilter.some((filterId) => id.includes(filterId))
        );
      }

      const allUploaded = allRequiredDocumentsUploaded(
        filteredDocuments,
        uploads,
        files
      );
      setOpen(false);

      if (!allUploaded) {
        toast.error(
          "Por favor, faça o envio de todos os documentos obrigatórios."
        );
        return;
      }

      await uploadFilesWithDelay(files, 1000, true);

      toast.success("O envio dos arquivos foi feito com sucesso.");
      navigate("/homeregistration");
      setOpen(false);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 413) {
        toast.error("Tamanho do arquivo excede o limite");
      } else {
        toast.error("Erro ao enviar arquivos, por favor tente novamente");
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <>
        <CenteredCircularProgress message={message} button={button} />
      </>
    );
  }

  const renderFileUploader = ({ name, id }) => {
    const uploadedFile = uploads.find((upload) => upload.uploadName === id);

    return (
      <Grid item xs={6} key={id}>
        <Typography sx={{ fontSize: 15, marginTop: 5 }}>{name}</Typography>
        <CardStyled>
          <CardContent id={id}>
            <FileUploader
              handleChange={(file) => handleUploadChange(id, file)}
              name="file"
              multiple={true}
              children={
                <div
                  style={{ textAlign: "center", color: "#e5007e", padding: 0 }}
                >
                  {files?.[id]?.length > 0 ? (
                    Object.keys(files?.[id]).map((v) => {
                      const uploadedFile = files?.[id][v];
                      return (
                        <>
                          <p
                            key={v}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "black",
                            }}
                          >
                            {uploadedFile.name}
                          </p>
                          <p>Arquivo carregado.</p>
                        </>
                      );
                    })
                  ) : uploadedFile ? (
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "black",
                      }}
                    >
                      <CheckCircleOutline
                        style={{ color: "green", fontWeight: "bold" }}
                      />{" "}
                      {uploadedFile.fileName}
                    </p>
                  ) : (
                    <p>
                      <CloudUploadOutlinedIcon /> Arraste e solte o arquivo
                      preenchido aqui
                    </p>
                  )}
                </div>
              }
            />
          </CardContent>
        </CardStyled>
      </Grid>
    );
  };

  const renderSocios = (socios, partnerIndex) => {
    return socios.map((doc) => {
      const uploadedFile = uploads.find(
        (upload) => upload.uploadName === `${doc.id}${partnerIndex}`
      );

      return (
        <Grid item xs={6} key={`${doc.id}${partnerIndex}`}>
          <Typography sx={{ fontSize: 15, marginTop: 5 }}>
            {doc.name}{" "}
          </Typography>
          <CardStyled>
            <CardContent id={`${doc.id}${partnerIndex}`}>
              <FileUploader
                handleChange={(file) =>
                  handleUploadChange(`${doc.id}${partnerIndex}`, file)
                }
                name="file"
                multiple={true}
                children={
                  <div
                    style={{
                      textAlign: "center",
                      color: "#e5007e",
                      padding: 0,
                    }}
                  >
                    {files?.[`${doc.id}${partnerIndex}`]?.length > 0 ? (
                      Object.keys(files?.[`${doc.id}${partnerIndex}`]).map(
                        (v) => {
                          const uploadedFile =
                            files?.[`${doc.id}${partnerIndex}`][v];
                          return (
                            <>
                              <p
                                key={v}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "black",
                                }}
                              >
                                {uploadedFile.name}
                              </p>
                              <p>Arquivo carregado.</p>
                            </>
                          );
                        }
                      )
                    ) : uploadedFile ? (
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "black",
                        }}
                      >
                        <CheckCircleOutline
                          style={{ color: "green", fontWeight: "bold" }}
                        />
                        {""}
                        {uploadedFile.fileName}
                      </p>
                    ) : (
                      <p>
                        <CloudUploadOutlinedIcon /> Arraste e solte o arquivo
                        preenchido aqui
                      </p>
                    )}
                  </div>
                }
              />
            </CardContent>
          </CardStyled>
        </Grid>
      );
    });
  };

  const getTotalDocuments = () => {
    let totalDocuments = [];
    if (analysis?.clientType === "VAR") {
      totalDocuments = [...documentosVAR];
      partners.forEach((partner, index) => {
        totalDocuments.push(
          ...(partner?.cpf?.length === 11
            ? sociosVAR.cpf11
            : sociosVAR.cpfMais11
          ).map((doc) => ({
            ...doc,
            id: `${doc.id}${index}`,
          }))
        );
      });
    } else if (analysis?.clientType === "PSP") {
      totalDocuments = [...documentosPSP, ...compliancePSP];
      partners.forEach((partner, index) => {
        totalDocuments.push(
          ...(partner?.cpf?.length === 11
            ? sociosPSP.cpf11
            : sociosPSP.cpfMais11
          ).map((doc) => ({
            ...doc,
            id: `${doc.id}${index}`,
          }))
        );
      });
    }
    return totalDocuments;
  };

  // const calculateProgress = () => {
  //   const totalDocuments = getTotalDocuments();
  //   const uploadedDocuments = uploads.filter((upload) =>
  //     totalDocuments.some((doc) => doc.id === upload.uploadName)
  //   ).length;

  //   const progress = Math.round((uploadedDocuments / totalDocuments.length) * 100);

  //   return Math.min(progress, 100);
  // };

  // const progress = calculateProgress();

  return (
    <>
      <Header name="Uploads" token={token} />
      {/* {loading && <CenteredCircularProgress />} */}

      <Container sx={{ marginBottom: "150px" }}>
        <Grid>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {" "}
            <h1 style={{ display: "flex", justifyContent: "center" }}>
              Uploads
            </h1>
            {/* <div style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ width: "100%", margin: "10px 0" }}
              />
              <Typography
                variant="body2"
                color="textSecondary"
              >{`Progresso: ${progress}%`}</Typography>
            </div> */}
            <SentUploads token={token} />
            <Typography sx={{ marginTop: 5, fontWeight: "bold" }}>
              Documentos empresariais{" "}
            </Typography>
          </div>
          <Grid container spacing={2}>
            {analysis?.clientType === "VAR" && (
              <>
                {documentosVAR.map(renderFileUploader)}
                {partners.map((partner, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Typography sx={{ marginTop: 5, fontWeight: "bold" }}>
                        Documentos do sócio {partner?.fullName}
                      </Typography>
                    </Grid>
                    {partner?.cpf?.length === 11
                      ? renderSocios(sociosVAR.cpf11, index)
                      : renderSocios(sociosVAR.cpfMais11, index)}
                  </React.Fragment>
                ))}
              </>
            )}

            {analysis?.clientType === "PSP" && (
              <>
                {documentosPSP.map(renderFileUploader)}
                {partners.map((partner, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Typography sx={{ marginTop: 5, fontWeight: "bold" }}>
                        Documentos do sócio {partner?.fullName}
                      </Typography>
                    </Grid>
                    {partner?.cpf?.length === 11
                      ? renderSocios(sociosPSP.cpf11, index)
                      : renderSocios(sociosPSP.cpfMais11, index)}
                  </React.Fragment>
                ))}
                <Grid item xs={12}>
                  <Typography sx={{ marginTop: 5, fontWeight: "bold" }}>
                    Documentos de compliance, PLD/FT, risco e chargeback
                  </Typography>
                </Grid>
                {compliancePSP.map(renderFileUploader)}
              </>
            )}
          </Grid>
        </Grid>
        <div
          style={{
            position: "fixed",
            bottom: 0,
            zIndex: 97,
            display: "flex",
            width: "70%",
            justifyContent: "end",
            marginBottom: "30px",
          }}
        >
          <FooterButtons
            ButtonStyle={ButtonStyle}
            SendButtonStyle={SendButtonStyle}
            onClickNext={onClickNext}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            open={open}
            onClickFinalize={onClickFinalize}
            textSend="os arquivos"
          />
        </div>
      </Container>
    </>
  );
}

export default Uploads;
