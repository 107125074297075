import React, { useEffect, useState, useCallback, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Header from "../../components/Header2";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CenteredCircularProgress from "../../components/CenteredCircularProgress";
import getQuestions, { useQuestions } from "./hooks/getQuestions";
import Checkbox from "@mui/material/Checkbox";
import { FormControl, Typography, Grid } from "@mui/material";
import axios from "axios";
import SaveQuestions from "./SaveQuestions";
import TableContacts from "../../components/Table";
import FinalizeQuestions from "./FinalizeQuestions";
import FooterButtons from "../../components/FooterButtons";
import LegalForm from "../Register/LegalForm";
import {
  formatAndValidateCep,
  formatCnpj,
  formatDate,
} from "../../utils/formattedTexts";

export const ButtonStyle = {
  color: "#e5007e",
  backgroundColor: "#fff5fa",
  borderRadius: "2.5rem",
  border: "solid 1px #e5007e",
  fontSize: "14px",
  paddingInline: "15px",
  boxShadow: "none",
  fontWeight: "500",
  ml: 5,
  "&:hover": {
    backgroundColor: "white",
    color: "#e5007e",
  },
};

export const SendButtonStyle = {
  backgroundColor: "#e5007e",
  borderRadius: "2.5rem",
  border: "solid 1px #e5007e",
  fontSize: "14px",
  paddingInline: "15px",
  boxShadow: "none",
  fontWeight: "500",
  ml: 5,
  "&:hover": {
    backgroundColor: "#e5007e",
    color: "white",
  },
};

const TextFieldForm = styled(TextField)({
  borderRadius: "6px",
  backgroundColor: "white",
});

function FormPsp(props) {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [answerFromDb, setAnswerFromDb] = useState(false);
  const analysis = JSON.parse(localStorage.getItem("analysis"));
  const [tempRadioAnswers, setTempRadioAnswers] = useState({});
  const [tableAnswers, setTableAnswers] = useState([]);
  const [open, setOpen] = useState(false);
  const files = React.useRef([]);
  const isMobile = window.innerWidth < 600;
  const navigate = useNavigate();
  const [answersLoaded, setAnswersLoaded] = useState(false);
  const [button, setButton] = useState("");

  const legalFormRef = useRef();

  const handleParentButtonClick = async () => {
    if (legalFormRef.current) {
      try {
        await legalFormRef.current.submitForm();
        return true; 
      } catch (error) {
        console.error("Erro ao submeter o formulário:", error);
        return false; 
      }
    }
    return false;
  };


  const fetchQuestionsAndAnswers = useCallback(async () => {
    setLoading(true);
    try {
      await getQuestions(
        setAnswers,
        setLoading,
        toast,
        props,
        setQuestions,
        answers,
        questions
      );

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/form/${analysis.id}`,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );

      const answersFromDb = response.data.map((answer) => ({
        questionAnswer: answer.questionAnswer,
        formId: answer.form.id,
      }));
      setAnswerFromDb(answersFromDb);

      const updatedAnswers = {};
      answersFromDb.forEach((answer) => {
        updatedAnswers[answer.formId] = {
          id: answer.formId,
          questionAnswer: answer.questionAnswer,
        };
      });

      setAnswers(answers, updatedAnswers);
      setAnswersLoaded(true);
    } catch (error) {
      console.error("Erro ao buscar perguntas e respostas:", error);
      toast.error("Erro ao buscar perguntas e respostas");
    } finally {
      setLoading(false);
    }
  }, [props, props.token]);

  const onClickNext = async () => {
    const isFormSubmitted = await handleParentButtonClick();
    if (isFormSubmitted) {
      setButton("save");
      SaveQuestions(
        answers,
        tempRadioAnswers,
        analysis,
        props,
        navigate,
        setSaving,
        files.current
      );
    }else{
      toast.error("Erro ao salvar o formulário. Por favor, tente novamente.");

    }
  };
  
  const onClickFinalize = async () => {
    const isFormSubmitted = await handleParentButtonClick();
    
    if (isFormSubmitted) {
      setButton("finalize");
  
      FinalizeQuestions(
        answers,
        tempRadioAnswers,
        analysis,
        props,
        navigate,
        setSaving,
        files.current,
        questions,
        answerFromDb
      );
    } else {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
    }
  };

  const handleTableChange = (questionId, value) => {
    setAnswers(
      answers,
      (answers[questionId] = { id: questionId, questionAnswer: value })
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleClose();
    onClickFinalize();
  };

  const getAnswersFromDb = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/form/${analysis.id}`,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );
      const answersFromDb = response.data.map((answer) => ({
        questionAnswer: answer.questionAnswer,
        formId: answer.form.id,
      }));
      setAnswerFromDb(answersFromDb);
    } catch (error) {
      console.error("Erro ao buscar respostas do banco de dados:", error);
      toast.error("Erro ao buscar respostas do banco de dados");
    } finally {
      setLoading(false);
    }
  }, [analysis.id]);
  

  const handleAnswerChange = (questionId, answer) => {
    setAnswers((prevTempRadioAnswers) => ({
      ...prevTempRadioAnswers,
      [parseInt(questionId)]: {
        questionAnswer: answer,
        id: questionId,
        index: questionId,
      },
    }));

    setTempRadioAnswers((prevTempRadioAnswers) => ({
      ...prevTempRadioAnswers,
      [parseInt(questionId)]: { answer: answer, id: questionId },
    }));
  };

  const handleTextChange = (id, value) => {
    if (value.trim() === "") {
      setAnswers((prevAnswers) => {
        const updatedAnswers = { ...prevAnswers };
        delete updatedAnswers[id];
        return updatedAnswers;
      });
    } else {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [id]: { id: id, questionAnswer: value },
      }));
    }
  };

  const renderQuestion = (question, onChange) => {
    return (
      <div key={question.id}>
        <h4 id={question.id}>{question.question}</h4>
        {renderFormField(question, handleTextChange)}
        {question.dependencyQuestionId &&
          answers[question.dependencyQuestionId] === "sim" &&
          renderDependentQuestions(question)}
      </div>
    );
  };

  const renderDependentQuestions = (question) => {
    const dependentQuestions = questions.filter(
      (q) => q.dependencyQuestionId === question.id
    );
    return dependentQuestions.map((dependentQuestion) => (
      <div className="dependentQuestion" key={dependentQuestion.id}>
        <h4 id={dependentQuestion.id}>{dependentQuestion.question}</h4>
        {renderFormField(dependentQuestion)}
      </div>
    ));
  };
  const handleCheckboxChange = (id, optionValue, checked) => {
    setAnswers((prevAnswers) => {
      const updatedAnswers = { ...prevAnswers };

      if (updatedAnswers[id]) {
        const values = updatedAnswers[id].questionAnswer.split(",");

        if (checked) {
          if (!values.includes(optionValue)) {
            values.push(optionValue);
          }
        } else {
          const index = values.indexOf(optionValue);
          if (index !== -1) {
            values.splice(index, 1);
          }
        }

        updatedAnswers[id].questionAnswer = values.join(",");
      } else {
        updatedAnswers[id] = {
          id: id,
          questionAnswer: optionValue,
        };
      }

      return updatedAnswers;
    });
  };

  const renderFormField = (question, onChange) => {
    let answer = answers[question.id] || { questionAnswer: "", checked: false };
    const answerFromDB = answerFromDb?.find(
      (dbAnswer) => dbAnswer.formId === question.id
    );

    let label = question.question;
    if (question.question === "Endereço:") {
      label = "Endereço (Rua, nº, complemento)";
    }

    const answerFromState = answers[question.id]?.questionAnswer || "";

    switch (question.questionType) {
      case "text":
      case "textArea":
        let textValue =
          answerFromState !== undefined
            ? answerFromState
            : answerFromDB
            ? answerFromDB.questionAnswer
            : "";

        let validationText = "";

        if (question.question === "CEP:") {
          textValue = formatAndValidateCep(textValue);

          const isEightDigits = textValue.replace(/\D/g, "").length === 8;
          validationText = isEightDigits
            ? ""
            : "Campo 'CEP' deve conter 8 dígitos";
        }
        if (
          question.question === "1.1 CNPJ" ||
          question.question === "3.1 CNPJ" ||
          question.question === "24.4 CNPJ da empresa responsável pelo AoC PCI:"
        ) {
          textValue = formatCnpj(textValue);
        }
        if (question.question === "1.4 Data de Constituição") {
          textValue = formatDate(textValue);
        }

        return (
          <>
            <TextFieldForm
              style={{ width: "100%" }}
              id={question.id}
              onChange={(event) => {
                const newValue = event.target.value;
                onChange(question.id, newValue === "" ? "" : newValue);
              }}
              value={textValue}
              InputLabelProps={{
                shrink: Boolean(answer.questionAnswer || textValue),
              }}
            />
            <Typography color="error">{validationText}</Typography>
          </>
        );

      case "radioGroup":
        const radioValue =
          answerFromState !== ""
            ? answerFromState
            : answerFromDB
            ? answerFromDB.questionAnswer
            : "";

        let options = [];

        try {
          const parsedOptions =
            typeof question.options === "string"
              ? JSON.parse(question.options)
              : question.options;
          options = parsedOptions.options ? parsedOptions.options : [];
        } catch (error) {
          console.error("Failed to parse question.options:", error);
        }


        if (options.length === 0) {
          console.warn("No options available for question:", question);
        }

        return (
          <RadioGroup
            sx={{ display: "flex", flexDirection: "row" }}
            id={question.id}
            onChange={(event) => {
              handleAnswerChange(question.id, event.target.value);
            }}
            value={radioValue || ""}
          >
            {options.length > 0 ? (
              options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  name="answer"
                />
              ))
            ) : (
              <Typography variant="body2">No options available</Typography>
            )}
          </RadioGroup>
        );

      case "checkbox":
        const checkboxValuesFromDB = answerFromDB
          ? answerFromDB.questionAnswer.split(",")
          : [];
        return (
          <FormControl component="fieldset">
            {question?.options.map((option, i) => (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    defaultChecked={checkboxValuesFromDB.includes(option.value)}
                    onChange={(event) =>
                      handleCheckboxChange(
                        question.id,
                        option.value,
                        event.target.checked
                      )
                    }
                  />
                }
                label={option.label}
              />
            ))}
          </FormControl>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchQuestionsAndAnswers();
  }, [fetchQuestionsAndAnswers]);
  
  useEffect(() => {
    getAnswersFromDb();
  }, []);

  useEffect(() => {
    if (answerFromDb.length > 0 && answers) {
      const updatedAnswers = { ...answers };

      answerFromDb?.forEach((answer) => {
        updatedAnswers[answer.formId] = {
          id: answer.formId,
          questionAnswer: answer.questionAnswer,
        };
      });

      setAnswers(updatedAnswers);
    }
  }, [answerFromDb]);

  if (loading) {
 
    return (
      <>
        <CenteredCircularProgress />
       
      </>
    );
  }

  return (
    <>
      <Header onClick={onClickNext} token={props.token} />
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <div className="Questions">
          <Container>
            <br />
            {loading && <CenteredCircularProgress />}
            <Container>
              <h1>Formulário</h1>
              <h3>Gostaríamos de conhecer sua estrutura operacional</h3>

              {analysis.clientType === "PSP" && (
                <>
                  {/* <SentUploads token={props.token} /> */}

                  <h3 style={{ color: "#e5007e" }}> DADOS CADASTRAIS </h3>
                  <h3>1. Dados da Empresa</h3>
                </>
              )}
              {isMobile ? (
                <Grid>
                  {questions.length > 0 &&
                    analysis.clientType === "PSP" &&
                    questions.map((question, index) => {
                      const dependencyAnswer =
                        answers[question.dependencyQuestionId]?.questionAnswer;

                      if (!answerFromDb) return;
                      const dependecyIds = answerFromDb?.find(
                        (a) => a.formId === question.id
                      );

                      if (
                        analysis.clientType === "PSP" &&
                        question.clientType === "PSP" &&
                        question.questionIdentifier === "1" &&
                        (!question.dependencyQuestionId ||
                          dependencyAnswer === "sim" ||
                          dependencyAnswer === "Outro" ||
                          dependencyAnswer === "PCI de um terceiro" ||
                          dependecyIds?.questionAnswer === "sim" ||
                          dependecyIds?.questionAnswer === "Outro" ||
                          dependecyIds?.questionAnswer === "PCI de um terceiro")
                      ) {
                        return (
                          <Grid item xs={6} key={question.id}>
                            {renderQuestion(question)}
                            {question.uploadMessageAnswer && (
                              <p> {question.uploadMessageAnswer} </p>
                            )}
                          </Grid>
                        );
                      }

                      return null;
                    })}
                </Grid>
              ) : (
                <Container>
                  <Grid container spacing={2}>
                    {questions.length > 0 &&
                      analysis.clientType === "PSP" &&
                      questions.map((question, index) => {
                        const dependencyAnswer =
                          answers[question.dependencyQuestionId]
                            ?.questionAnswer;

                        if (!answerFromDb) return;
                        const dependecyIds = answerFromDb?.find(
                          (a) => a.formId === question.id
                        );

                        if (
                          analysis.clientType === "PSP" &&
                          question.clientType === "PSP" &&
                          question.questionIdentifier === "1" &&
                          (!question.dependencyQuestionId ||
                            dependencyAnswer === "sim" ||
                            dependencyAnswer === "Outro" ||
                            dependencyAnswer === "PCI de um terceiro" ||
                            dependecyIds?.questionAnswer === "sim" ||
                            dependecyIds?.questionAnswer === "Outro" ||
                            dependecyIds?.questionAnswer ===
                              "PCI de um terceiro")
                        ) {
                          return (
                            <Grid item xs={6} key={question.id}>
                              {renderQuestion(question)}
                              {question.uploadMessageAnswer && (
                                <p> {question.uploadMessageAnswer} </p>
                              )}
                            </Grid>
                          );
                        }

                        return null;
                      })}
                  </Grid>
                </Container>
              )}

              {analysis.clientType === "PSP" && (
                <>
                  <h3 style={{ color: "#e5007e" }}>
                    PLANO DE NEGÓCIO E MODO DE ATUAÇÃO
                  </h3>
                </>
              )}
              {questions.length > 0 &&
                analysis.clientType === "PSP" &&
                questions.map((question, index) => {
                  if (!answerFromDb) return;

                  const dependecyIds = answerFromDb?.find(
                    (a) => a.formId === question.id
                  );

                  if (
                    analysis.clientType === "PSP" &&
                    question.clientType === "PSP" &&
                    (question.questionIdentifier === "1.2" ||
                      question.questionIdentifier === "2") &&
                    (!question.dependencyQuestionId ||
                      answers[question.dependencyQuestionId]?.questionAnswer ===
                        "sim" ||
                      answers[question.dependencyQuestionId]?.questionAnswer ===
                        "Outro" ||
                      answers[question.dependencyQuestionId]?.questionAnswer ===
                        "PCI de um terceiro" ||
                      dependecyIds?.questionAnswer === "sim" ||
                      dependecyIds?.questionAnswer === "Outro" ||
                      dependecyIds?.questionAnswer === "PCI de um terceiro")
                  ) {
                    return (
                      <>
                        {renderQuestion(question)}

                        {question.uploadMessageAnswer ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: question.uploadMessageAnswer,
                            }}
                          />
                        ) : null}

                        {question.id === 222 && (
                          <div>
                            <TableContacts
                              questions={questions}
                              key={question.id}
                              answers={answers}
                              handleTableChange={handleTableChange}
                            />
                          </div>
                        )}
                      </>
                    );
                  }

                  return null;
                })}
              {analysis.clientType === "PSP" && (
                <>
                  <h3 style={{ color: "#e5007e" }}>
                    REQUISITOS DE COMPLIANCE, RISCO, PLD/FT, RISCO E CHARGEBACK
                  </h3>
                </>
              )}
              {questions.length > 0 &&
                analysis.clientType === "PSP" &&
                questions.map((question) => {
                  if (!answerFromDb) return;

                  const dependecyIds = answerFromDb?.find(
                    (a) => a.formId === question.id
                  );

                  if (
                    analysis.clientType === "PSP" &&
                    question.clientType === "PSP" &&
                    question.questionIdentifier === "3" &&
                    (!question.dependencyQuestionId ||
                      answers[question.dependencyQuestionId]?.questionAnswer ===
                        "sim" ||
                      answers[question.dependencyQuestionId]?.questionAnswer ===
                        "Outro" ||
                      answers[question.dependencyQuestionId]?.questionAnswer ===
                        "PCI de um terceiro" ||
                      dependecyIds?.questionAnswer === "sim" ||
                      dependecyIds?.questionAnswer === "Outro" ||
                      dependecyIds?.questionAnswer === "PCI de um terceiro")
                  ) {
                    return (
                      <>
                        {renderQuestion(question)}

                        {question.uploadMessageAnswer ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: question.uploadMessageAnswer,
                            }}
                          />
                        ) : null}

                        {question.id === 222 && (
                          <div>
                            <h3> Dados dos Administradores</h3>
                            <TableContacts
                              questions={questions}
                              key={question.id}
                              answers={answers}
                              handleTableChange={handleTableChange}
                            />
                          </div>
                        )}
                      </>
                    );
                  }

                  return null;
                })}
              {analysis.clientType === "VAR" && (
                <>
                  <p>
                    {" "}
                    Algumas perguntas precisam ser complementadas com
                    documentos. O anexo dos arquivos poderá ser feito
                    posteriormente na área de{" "}
                    <a style={{ color: "#e5007e" }} href="/uploads">
                      Uploads{" "}
                    </a>
                  </p>

                  <h3 style={{ color: "#e5007e" }}> Dados da Empresa</h3>
                </>
              )}
              {questions.length > 0 &&
                analysis.clientType === "VAR" &&
                questions.map((question) => {
                  if (!answerFromDb) return;

                  const dependecyIds = answerFromDb?.find(
                    (a) => a.formId === question.id
                  );

                  if (
                    analysis.clientType === "VAR" &&
                    question.clientType === "VAR" &&
                    (!question.dependencyQuestionId ||
                      answers[question.dependencyQuestionId]?.questionAnswer ===
                        "sim" ||
                      answers[question.dependencyQuestionId]?.questionAnswer ===
                        "Outro" ||
                      answers[question.dependencyQuestionId]?.questionAnswer ===
                        "PCI de um terceiro" ||
                      dependecyIds?.questionAnswer === "sim" ||
                      dependecyIds?.questionAnswer === "Outro" ||
                      dependecyIds?.questionAnswer === "PCI de um terceiro")
                  ) {
                    return (
                      <>
                        {renderQuestion(question)}

                        {question.uploadMessageAnswer ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: question.uploadMessageAnswer,
                            }}
                          />
                        ) : null}
                      </>
                    );
                  }

                  return null;
                })}
            </Container>
            <LegalForm token={props.token} ref={legalFormRef} button={button} />
          </Container>
        </div>
      </Box>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "90%",
          zIndex: 97,
          marginBottom: "20px",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <FooterButtons
          ButtonStyle={ButtonStyle}
          SendButtonStyle={SendButtonStyle}
          onClickNext={onClickNext}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={open}
          onClickFinalize={onClickFinalize}
          textSend="o formulário"
        />
      </div>
    </>
  );
}
export default FormPsp;
