export const formatCPFOrCNPJ = (value) => {
    value = value.replace(/\D/g, "");
    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else if (value.length === 14) {
      value = value.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        "$1.$2.$3/$4-$5"
      );
    }

    return value;
  };


  export const formatTelefone = (telefone) => {
    const numericTelefone = telefone.replace(/\D/g, "");
  
    const formattedTelefone = numericTelefone.replace(
      /^(\d{2})(\d{4})(\d{4})$/,
      "($1)$2-$3"
    );
  
    return formattedTelefone;
  };

  export const formatCnpj = (cnpj) => {
    const numericCnpj = cnpj.replace(/\D/g, "");

    const formattedCnpj = numericCnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      "$1.$2.$3/$4-$5"
    );

    return formattedCnpj;
  };

  export  const formatDate = (date) => {
    if (!date) return "";

    const formattedDate = date.replace(/^(\d{4})-(\d{2})-(\d{2})$/, "$3/$2/$1");

    return formattedDate;
  };

  export const formatAndValidateCep = (cep) => {
    const numericCep = cep.replace(/\D/g, "");

    const formattedCep = numericCep.replace(/^(\d{5})(\d{3})$/, "$1-$2");

    return formattedCep;
  };