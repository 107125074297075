import * as React from "react";
import { styled } from "@mui/material/styles";
import { Step, StepLabel } from "@mui/material";
import MuiStepper from "@mui/material/Stepper";

require('../pages/tokenRenewal')

const steps = [
  "Cadastro",
  "Análise da Parceria e Garantia",
  "Precificação",
  "Proposta Comercial",
  "Contrato",
];

const HeaderStepper = styled(MuiStepper)({
  width: "60%",
  "& .MuiStep-root": {
    paddingTop: 0, // Decrease the spacing between the circles
    paddingBottom: 0, // Decrease the spacing between the circles
  },
});

const HeaderH4 = styled("h4")({
  textAlign: "center",
});

const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  paddingBottom: "25px",
});

const CustomStepLabel = styled(StepLabel)(({ theme, active }) => ({
  "& .MuiStepIcon-root": {
    width: 100, // Increase the circle size
    height: 32, // Increase the circle size
    borderRadius: "50%", // Make the circle shape
    // color: "#e5007e"
  },
  

}));

const CurrentStep = styled(Step)(({ theme, active }) => ({
  "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active, .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed ": {
   color: "#e5007e !important",
   fontWeight: "bold"
  },

}));

function Stepper(props) {
  return (
    <>
      <HeaderH4>Status do seu processo</HeaderH4>
      <Container>
        <HeaderStepper activeStep={props.activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <CurrentStep key={label}>
              <CustomStepLabel active={props.activeStep === index}>{label}</CustomStepLabel>
            </CurrentStep>
          ))}
        </HeaderStepper>
      </Container>
    </>
  );
}

export default Stepper;
