import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { get } from "lodash";

const months = [
  {
    label: "MÊS 1",
    value: "[1].__EMPTY_1",
  },
  {
    label: "MÊS 2",
    value: "[2].__EMPTY_1",
  },
  {
    label: "MÊS 3",
    value: "[3].__EMPTY_1",
  },
  {
    label: "MÊS 4",
    value: "[4].__EMPTY_1",
  },
  {
    label: "MÊS 5",
    value: "[5].__EMPTY_1",
  },
  {
    label: "MÊS 6",
    value: "[6].__EMPTY_1",
  },
  {
    label: "MÊS 7",
    value: "[7].__EMPTY_1",
  },
  {
    label: "MÊS 8",
    value: "[8].__EMPTY_1",
  },
  {
    label: "MÊS 9",
    value: "[9].__EMPTY_1",
  },
  {
    label: "MÊS 10",
    value: "[10].__EMPTY_1",
  },
  {
    label: "MÊS 11",
    value: "[11].__EMPTY_1",
  },
  {
    label: "MÊS 12",
    value: "[12].__EMPTY_1",
  },
];

const produto = [
  {
    label: "Débito",
    value: "[1].__EMPTY_4",
    indicador: "[1].__EMPTY_5",
  },
  {
    label: "Crédito",
    value: "[2].__EMPTY_4",
    indicador: "",
  },
  {
    label: "Parcelado",
    value: "[3].__EMPTY_4",
    indicador: "",
  },
];
const tecnologia = [
  {
    label: "Mundo Físico (Cartão Presente)",
    value: "[6].__EMPTY_4",
    indicador: "[6].__EMPTY_5",
  },
  {
    label: "Digital (Cartão Não Presente)",
    value: "[7].__EMPTY_4",
    indicador: "",
  },
];

const antecipacao = [
  {
    label: "% Antecipação",
    value: "[9].__EMPTY_4",
  },
  {
    label: "Prazo Médio Liquidação (dias)",
    value: "[10].__EMPTY_4",
  },
];

const currencyLocale = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const ForecastPreview = ({ data, mccs }) => {

  return (
    <Box>
      <Box>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <h3>Por favor confirme os dados preenchidos:</h3>
          </Grid>
          <Grid item xs={5}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      FATURAMENTO TOTAL POR MÊS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {months.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell>
                        {currencyLocale.format(get(data, row.value, ""))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <br />
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      ANO
                    </TableCell>
                    <TableCell>
                      {currencyLocale.format(get(data, "[13].__EMPTY_1", ""))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={7}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>% atuação por produto</TableCell>
                    <TableCell>Proporção</TableCell>
                    <TableCell>Indicador % produto</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {produto.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell>{(get(data, row.value, "") * 100).toFixed(2)}%</TableCell>
                      <TableCell>
                        {index === 0
                          ? `${(get(data, row.indicador, "") * 100).toFixed(2)}%`
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <br />
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>% atuação por tecnologia</TableCell>
                    <TableCell>Proporção</TableCell>
                    <TableCell>Indicador % tecnologia</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tecnologia.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell>{(get(data, row.value, "") * 100).toFixed(2)}%</TableCell>
                      <TableCell>
                        {index === 0
                          ? `${(get(data, row.indicador, "") * 100).toFixed(2)}%`
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <br />
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  {antecipacao.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell>
                        {index === 0
                          ? `${(get(data, row.value, "") * 100).toFixed(2)}%`
                          : get(data, row.value, "")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <h3>Carteira de MCCs</h3>
          </Grid>
          <Grid item xs={3}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Indicador % Carteira
                    </TableCell>
                    <TableCell>
                      {((Math.round(get(data, "[16].__EMPTY_1", "") * 100) / 100) * 100).toFixed(2)}%
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>MCC</TableCell>
                    <TableCell>% que representa na carteira</TableCell>
                    <TableCell>Produto</TableCell>
                    <TableCell>Ticket Médio por Produto e MCC</TableCell>
                    <TableCell>TPV total do MCC</TableCell>
                    <TableCell>
                      Projeção do faturamento anual do MCC por produto
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mccs.map((mcc) => {
                    return (
                      <TableRow
                        key={mcc.mcc}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {mcc.mcc}
                        </TableCell>
                        <TableCell align="center">{Math.round(mcc.mccBillingProjectionByProduct)}%</TableCell>
                        <TableCell>
                          DEBITO
                          <br />
                          CREDITO
                          <br />
                          PARCELADO
                          <br />
                        </TableCell>
                        <TableCell>
                          {currencyLocale.format(mcc.debit)}
                          <br />
                          {currencyLocale.format(mcc.credit)}
                          <br />
                          {currencyLocale.format(mcc.installment)}
                        </TableCell>
                        <TableCell>{currencyLocale.format(mcc.mccTotalTpv)}</TableCell>
                        <TableCell>
                          {currencyLocale.format(mcc.averageTicketDebit)}
                          <br />
                          {currencyLocale.format(mcc.averageTicketCredit)}
                          <br />
                          {currencyLocale.format(mcc.averageTicketInstallments)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

ForecastPreview.defaultProps = {
  data: [],
};
ForecastPreview.propTypes = {
  data: PropTypes.array,
};

export default ForecastPreview;
