import { useQuery } from "@tanstack/react-query";
import { Api } from "../../../config/Api";
import { toast } from "react-toastify";
import axios from "axios";
const analysis = JSON.parse(localStorage.getItem("analysis"));

const getQuestions = async (
  setAnswers,
  setLoading,
  toast,
  props,
  setQuestion,
  answers,
  questions,
  partners,
  setPartners
) => {
  setLoading(true);

  try {
    const response = await Api.get("/questions/" + analysis.id, {
      headers: {
        Authorization: props.token,
        "Content-Type": "application/json",
      },
    });

    const updatedQuestions = response.data.map((question) => {
      if (question.questionType === "checkbox" && question.options) {
        const options = JSON.parse(question.options).options;
        return {
          ...question,
          options: options,
        };
      }
      return question;
    });

    setQuestion(updatedQuestions);

    const dadosCadastrais = analysis.bigData.bigData.bigData?.dadosCadastrais ? analysis.bigData.bigData.bigData?.dadosCadastrais : analysis.bigData?.bigData?.dadosCadastrais;
    const addresses = analysis.bigData.bigData.bigData?.addresses ? analysis.bigData.bigData.bigData?.addresses : analysis.bigData.bigData?.addresses;
    
    if (dadosCadastrais && dadosCadastrais.length > 0) {
      await response.data.map((question) => {
        let arrAnswers = answers;
    
        if (question.question === "1.1 CNPJ" || question.question === "CNPJ:") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: analysis.dataCnpj.cnpj,
            index: question.id,
          };
        }
    
        if (question.question === "1.2 Razão Social" || question.question === "Razão Social:") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: JSON.parse(dadosCadastrais).Result[0].BasicData.OfficialName,
            index: question.id,
          };
        }
    
        if (question.question === "1.3 Nome Fantasia" || question.question === "Nome Fantasia:") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: analysis.dataCnpj.name,
            index: question.id,
          };
        }
    
        if (question.question === "1.4 Data de Constituição" || question.question === "Data de Constituição:") {
          const parts = JSON.parse(dadosCadastrais).Result[0].BasicData.FoundedDate.split("T");
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: parts[0],
            index: question.id,
          };
        }
    
        if (question.question === "1.5 Endereço" || question.question === "Endereço Sede (Rua, nº, complemento):") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: JSON.parse(addresses).Result[0].ExtendedAddresses?.Addresses[0].AddressMain,
            index: question.id,
          };
        }
    
        if (question.questionIdentifier === "BAIRRO") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: JSON.parse(addresses).Result[0].ExtendedAddresses?.Addresses[0].Neighborhood,
            index: question.id,
          };
        }
    
        if (question.questionIdentifier === "CIDADE") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: JSON.parse(addresses).Result[0].ExtendedAddresses?.Addresses[0].City,
            index: question.id,
          };
        }
    
        if (question.questionIdentifier === "ESTADO") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: JSON.parse(addresses).Result[0].ExtendedAddresses?.Addresses[0].State,
            index: question.id,
          };
        }
    
        if (question.questionIdentifier === "CEP") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: JSON.parse(addresses).Result[0].ExtendedAddresses?.Addresses[0].ZipCode,
            index: question.id,
          };
        }
    
        setAnswers(arrAnswers);
      });
    
    

      
    } else {
      // let responses = analysis.bigData.bigData.responses;

      // for (let response of responses) {
      //   let responses = analysis.bigData.bigData.responses;

      //   for (response of responses) {
      //     let matchKey = response.Result[0].MatchKeys;
      //     let match = matchKey.match(/doc\{(\d+)\}/);

      //     if (match) {
      //       let number = match[1];

      //       if (number.length === 11) {
      //         console.log(`11 dígitos: ${number}`);
      //       } else {
      //         console.log(`Mais de 11 dígitos: ${number}`);
      //       }
      //     } else {
      //       console.log("Formato inválido:", matchKey);
      //     }
      //   }
      // }

      await response.data.map((question) => {
        let arrAnswers = answers;
        if (question.question === "1.1 CNPJ" || question.question === "CNPJ:") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: analysis.dataCnpj.cnpj,
            index: question.id,
          };
        }

        if (question.question === "1.2 Razão Social"|| question.question === "Razão Social:") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: 
              analysis.bigData.bigData.responses[0].Result[0].BasicData.OfficialName,
            index: question.id,
          };
        }

        if (question.question === "1.3 Nome Fantasia"|| question.question === "Nome Fantasia:") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: analysis.dataCnpj.name,            
            index: question.id,
          };
        }

        if (question.question === "1.4 Data de Constituição" || question.question === "Data de Constituição:") {
          const foundedDate = analysis.bigData.bigData.responses[0].Result[0].BasicData.FoundedDate;
          const date = new Date(foundedDate);
          
          const day = String(date.getUTCDate()).padStart(2, '0');
          const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
          const year = date.getUTCFullYear();
          
          const formattedDate = `${day}/${month}/${year}`;
          
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: formattedDate,
            index: question.id,
          };
        }

        if (question.question === "1.5 Endereço"||question.question === "Endereço Sede (Rua, nº, complemento):") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: analysis?.bigData?.bigData?.responses?.[0]?.Result?.[0]?.ExtendedAddresses?.Addresses?.[0]?.AddressMain,
            index: question.id,
          };
        }

        if (question.questionIdentifier === "BAIRRO") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: 
              analysis?.bigData?.bigData?.responses?.[0]?.Result?.[0]?.ExtendedAddresses?.Addresses?.[0]?.Neighborhood,
            index: question.id,
          };
        }

        if (question.questionIdentifier === "CIDADE") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: analysis?.bigData?.bigData?.responses?.[0]?.Result?.[0]?.ExtendedAddresses?.Addresses?.[0]?.City,
            index: question.id,
          };
        }

        if (question.questionIdentifier === "ESTADO") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: analysis?.bigData?.bigData?.responses?.[0]?.Result?.[0]?.ExtendedAddresses?.Addresses?.[0]?.State,
            index: question.id,
          };
        }

        if (question.questionIdentifier === "CEP") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: analysis?.bigData?.bigData?.responses?.[0]?.Result?.[0]?.ExtendedAddresses?.Addresses?.[0]?.ZipCode,
            index: question.id,
          };
        }

        setAnswers(arrAnswers);
      });

      
    }

    // let newPartners = JSON.parse(
    //   analysis?.neowayData?.neowayData?.neowayData
    // ).beneficiarios;
    // let newObj = [];
    // newPartners?.map((partner, index) => {
    //   newObj[index] = {
    //     fullName: partner.nome,
    //     rg: "",
    //     cpf: partner.documento,
    //   };
    //   setPartners(newObj);
    // });

    // const partner = await axios.get(
    //         process.env.REACT_APP_API_URL + "/formpartner/" + analysis.id,
    //         {
    //           headers: {
    //             Authorization: props.token,
    //             "Content-Type": "application/json",
    //           },
    //         }
    //       );
    //       const Answers = partner.data;
    //       setPartners(Answers);
    //       console.log(partners, 'partnerss')

  } catch (error) {
    console.error(error);
    toast.error("Erro ao buscar perguntas");
  } finally {
    setLoading(false);
  }
};

export default getQuestions;
