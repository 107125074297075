import { Grid } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import logo from "../../../src/assets/Logo-Entrepay-Fundo-Vazio.png";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import axios from "axios";
import { useParams } from "react-router-dom";

require('../tokenRenewal')


const HeaderLogo = styled("img")({
  width: "254px",
  // height: "40px",
});

const ButtonStyled = styled(Button)({
  marginTop: "29px",
  backgroundColor: "#e5017e",
  color: "white",
  padding: "10px",
  borderRadius: "8px",
  width: "100%",
  "&:hover": {
    backgroundColor: "#e5017e",
  },
});
const LogoStyled = styled("div")({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  padding: "40px",
  flexDirection: "column",
});
const TextFieldWrapper = styled(TextField)`
  width: 100%;
  fieldset {
    border-radius: 8px;
  }
`;

function SignUp() {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const onChangePasswordConfirm = (event) => {
    setPasswordConfirm(event.target.value);
  };

  let { uuid } = useParams();

  const getUserSignup = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_NO_TOKEN_URL + "/terms/" + uuid
        
      );
      setEmail(response.data.analysis.email);
      // //console.log(response);
    } catch (error) {
      console.error(error);
      navigate("/signin/");
    
    }
  };
  React.useEffect(() => {
    getUserSignup();
  });

  const onClickSignUp = async (event) => {
    if (isEmail(email) !== true) {
      return toast.error("email invalido");
    }

    if (password !== passwordConfirm || password === "") {
      return toast.error("senhas inseridas não correspondem");
    }
    if (password.length <= 6) {
      return toast.error("senha deve conter no minimo 7 caracteres");
    }

    const auth = getAuth();
    const tenantId = process.env.REACT_APP_TENANT_ID;
    auth.tenantId = tenantId;

    try {

      localStorage.clear();
      
      const user = await createUserWithEmailAndPassword(auth, email, password);
      navigate("/signin/");

      // //console.log(user);
    } catch (error) {
      console.error(error);
      const errorCode = error.code;
      if (errorCode === "auth/email-already-in-use") {
        return toast.error("email ja existe");
      }
      toast.error("Erro ao tentar criar conta, tente novamente");
    }

    
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item>
        <Card
          sx={{
            marginTop: 10,
            marginBottom: 10,
            boxShadow: "0 0 1em #DCDCDC",
            padding: 10,
          }}
        >
          <LogoStyled>
            <HeaderLogo className="logo" src={logo} />
            <Typography
              sx={{
                marginBottom: 3,
                marginTop: 3,
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              Crie seu login
            </Typography>
          </LogoStyled>

          <Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 14 }}>Email</Typography>
              <TextFieldWrapper
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                placeholder="Insira um email"
                value={email}
                onChange={onChangeEmail}
                disabled={true}
              />
            </Grid>
            <Grid item>
              <Typography sx={{ marginTop: 3, fontSize: 14 }}>
                Nova senha
              </Typography>
              <TextFieldWrapper
                id="outlined-basic"
                label=""
                variant="outlined"
                type="password"
                size="small"
                placeholder="Insira uma senha"
                value={password}
                onChange={onChangePassword}
              />
            </Grid>
            <Grid item>
              <Typography sx={{ marginTop: 3, fontSize: 14 }}>
                Confirmar senha
              </Typography>
              <TextFieldWrapper
                id="outlined-basic"
                label=""
                type="password"
                placeholder="Inserir a senha novamente"
                variant="outlined"
                size="small"
                value={passwordConfirm}
                onChange={onChangePasswordConfirm}
              />
            </Grid>
            <Grid item>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <ButtonStyled onClick={onClickSignUp} size="small">
                Criar Senha
                </ButtonStyled>

                <Typography sx={{ marginTop: 2 }}>
                  Já possui login? <Link to={"/signin/"}>Acesse aqui</Link>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
export default SignUp;
