import React from "react";
import { CircularProgress, Container } from "@mui/material";

require("../pages/tokenRenewal");

const CenteredCircularProgress = ({ message, button }) => {
  
  const saveUploads =
    message && button === "save" ? (
      <>
        <p>Realizando o upload dos documentos. Por favor, aguarde...</p>
      </>
    ) : null;

  const finalizeUploads =
    message && button === "finalize" ? (
      <>
        <p>Realizando o envio de documentos. Por favor, aguarde...</p>
      </>
    ) : null;

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Adjust this to the desired height
        zIndex: 99999,
        flexDirection: "column"
      }}
    >
      <CircularProgress style={{ color: "#e5007e" }} />
      {saveUploads}
      {finalizeUploads}
    </Container>
  );
};

export default CenteredCircularProgress;
