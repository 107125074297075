import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useState, useEffect } from "react";
require("../../tokenRenewal");

function SentUploads({ token }) {
  const analysis = JSON.parse(localStorage.getItem("analysis"));
  const [loading, setLoading] = useState(false);
  const [uploads, setUploads] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const processedUploads = new Set();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "50%",
    overflowY: "scroll",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getUploads = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/documentsupload/" + analysis.id,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      const db = response.data.map((upload) => ({
        uploadName: upload.documentType,
      }));
      setUploads(db);
    } catch (error) {
      console.error(error, "error");
      //   navigate("/signin/");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUploads();
    // //console.log(uploads, "UPLOADSS");
  }, []);

  const renderUploads = uploads.filter(
    (upload, index, self) =>
      index === self.findIndex((t) => t.uploadName === upload.uploadName)
  );

  return (
    <>
      <div className="Modal">
        <Button
          sx={{
            color: "white",
            backgroundColor: "#e5007e",
            borderColor: "#e5007e",
            borderRadius: "2.5rem",
            mt: 2,
            "&:hover": {
              color: "#e5007e",
              backgroundColor: "white",
              borderColor: "#e5007e",
            },
          }}
          variant="outlined"
          onClick={handleOpen}
        >
          Clique para ver os arquivos já enviados
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h3 className="title">Arquivos já enviados: </h3>
            {renderUploads.length > 0 ? (
              renderUploads.map((upload, index) => (
                <div key={index}>
                  <ul className="custom-list">
                    {upload.uploadName === "cartaoCnpj" && (
                      <li>Cartão CNPJ da empresa</li>
                    )}
                    {upload.uploadName === "varProof" && (
                      <li>Comprovante de endereço da empresa</li>
                    )}
                    {upload.uploadName.includes("RG") && (
                      <li>Documento de Identidade com Foto (sócio)</li>
                    )}
                    {upload.uploadName.includes("CPF") && (
                      <li>Documento CPF (sócio)</li>
                    )}
                    {upload.uploadName.includes("proofResidencePartner") && (
                      <li>Comprovante de Endereço (sócio)</li>
                    )}
                    {upload.uploadName.includes("tax") && (
                      <li>Imposto de Renda dos 2 Anos Anteriores (sócio)</li>
                    )}
                    {upload.uploadName.includes("contractPartner") && (
                      <li>Contrato Social Atualizado (sócio)</li>
                    )}
                    {upload.uploadName === "treinamento_pld_compliance" && (
                      <li>Certificado do treinamento de PLD</li>
                    )}



                    {upload.uploadName === "registradora_contrato" && (
                      <li>Cópia do contrato com a registradora</li>
                    )}
                    {upload.uploadName === "curriculo_compliance" && (
                      <li>Currículo do Profissional de Compliance/PLD</li>
                    )}
                    {upload.uploadName === "treinamento_pld_ft_compliance" && (
                      <li>
                        Certificado do treinamento de PLD (do Diretor de
                        Compliance)
                      </li>
                    )}
                    {upload.uploadName === "sistema_antifraude" && (
                      <li>
                       Sistema Antifraude utilizado para monitoramento das transações
                      </li>
                    )}
                     {upload.uploadName === "evidencia_chargeback" && (
                      <li>
                       Evidência da equipe e dos procedimentos destinados a orientar os Estabelecimentos na tratativa de disputas (chargeback)
                      </li>
                    )}

                    {upload.uploadName === "contrato_prestacao" && (
                      <li>Contrato de prestação de serviços</li>
                    )}

                    {upload.uploadName === "contract" && (
                      <li>Contrato Social atualizado da empresa</li>
                    )}
                    {upload.uploadName.includes("financialBalance") && (
                      <li>
                        Balanço Financeiro e DRE dos 2 anos anteriores (sócio)
                      </li>
                    )}
                    {upload.uploadName === "evidencia_sub_adq" && (
                      <li>
                        Evidência da 1ª página do contrato entre a Subadquirente
                        e a Adquirente (pág. onde constam as informações de
                        cadastro)
                      </li>
                    )}
                    {upload.uploadName === "contrato_registradora" && (
                      <li>Cópia do contrato com a registradora</li>
                    )}
                    {upload.uploadName === "treinamento_pld_ft" && (
                      <li>Treinamento de PLD/FT (equipe) </li>
                    )}
                    {upload.uploadName === "proofResidence" && (
                      <li>Comprovante de Residência</li>
                    )}
                    {upload.uploadName.includes("tax") && (
                      <li>
                        Declaracao do Imposto de renda dos 2 anos anteriores dos
                        sócios
                      </li>
                    )}
                    {upload.uploadName === "prevencao_fraude" && (
                      <li>
                        Manual de Prevenção à Fraude com os alertas/regras
                        definidos
                      </li>
                    )}
                    {upload.uploadName === "contrato_plataformas" && (
                      <li>
                        Plataformas/sistemas(birôs, etc) utilizados (evidência:
                        print screen do sistema, contrato com birôs...)
                      </li>
                    )}
                    {upload.uploadName === "indicadores" && (
                      <li>Evidência de acompanhamento dos indicadores</li>
                    )}
                   
                    {upload.uploadName === "politicas_kyc" && (
                      <li>Políticas de KYC, KYP, KYE, KYS </li>
                    )}
                    {upload.uploadName === "certificacao_pci" && (
                      <li>Certificação PCI </li>
                    )}
                    {upload.uploadName === "plataformas_sistemas" && (
                      <li>
                        Plataformas/Sistemas utilizados para monitoramento das
                        transações{" "}
                      </li>
                    )}
                    {upload.uploadName === "evidencia_procedimento" && (
                      <li>
                        Evidência dos procedimentos para reporte de operação
                        suspeita de LD à credenciadora{" "}
                      </li>
                    )}
                    {upload.uploadName === "kyp_kys" && (
                      <li>Avaliação KYP/KYS</li>
                    )}
                    {upload.uploadName === "others" && <li>Outros</li>}
                    {upload.uploadName === "pld_ft" && <li>Política PLD/FT</li>}
                    {upload.uploadName === "organograma" && (
                      <li>Organograma funcional </li>
                    )}
                    {upload.uploadName !== "cartaoCnpj" &&
                      upload.uploadName !== "proofResidence" &&
                      upload.uploadName !== "contract" &&
                      upload.uploadName !== "financialBalance" &&
                      upload.uploadName !== "treinamento_pld_ft" &&
                      upload.uploadName !== "proofResidence" &&
                      upload.uploadName !== "tax" &&
                      upload.uploadName !== "prevencao_fraude" &&
                      upload.uploadName !== "contrato_plataformas" &&
                      upload.uploadName !== "indicadores" &&
                      upload.uploadName !== "CPF" &&
                      upload.uploadName !== "politicas_kyc" &&
                      upload.uploadName !== "certificacao_pci" &&
                      upload.uploadName !== "plataformas_sistemas" &&
                      upload.uploadName !== "evidencia_procedimento" &&
                      upload.uploadName !== "pld_ft" &&
                      upload.uploadName !== "kyp_kys" &&
                      upload.uploadName !== "others" &&
                      upload.uploadName !== "treinamento_pld_ft_compliance" &&
                      upload.uploadName !== "curriculo_compliance" &&
                      upload.uploadName !== "registradora_contrato" &&
                      upload.uploadName !== "varProof" &&
                      upload.uploadName !== "contrato_prestacao" &&
                      upload.uploadName !== "contrato_registradora" &&
                      upload.uploadName !== "evidencia_chargeback" &&
                      upload.uploadName !== "treinamento_pld_compliance" &&
                      upload.uploadName !== "sistema_antifraude" &&

                      !(
                        upload.uploadName.includes("financialBalance") ||

                        upload.uploadName.includes("financialBalance") ||
                        upload.uploadName.includes("RG") ||
                        upload.uploadName.includes("CPF") ||
                        upload.uploadName.includes("proofResidence") ||
                        upload.uploadName.includes("tax") ||
                        upload.uploadName.includes("contractPartner")
                      ) && <li>{upload.uploadName}</li>}
                  </ul>
                </div>
              ))
            ) : (
              <p>Nenhum arquivo enviado até o momento.</p>
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default SentUploads;
